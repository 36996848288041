(

	function($) {

		$.fn.initArguments = function() {

			this.find(".arg").addClass("hidden").waypoint(function(direction) {

				var $this = $(this.element);

				window.setTimeout(function() {

					$prevArg = $this.closest(".arguments").prev();

					if (direction == "down") {
						$this.removeClass("hidden");
						if ($prevArg.is(".arguments")) {
							$prevArg.addClass("hidden-arrow");
						}

					} else {

						/*
						$this.addClass("hidden");
						if ($prevArg.is(".arguments")) {
							$prevArg.removeClass("hidden-arrow");
						}
						*/					

					}

				}, 300);

			}, {

				offset: function() {
					var argh = $(this.element).height();
					var wh = window.innerHeight;
					var ww = window.innerWidth;

					if (wh > 650 && ww > 560) {
						return Math.round(wh - argh * 0.3);
					} else {
						return Math.round(wh - argh * 0.2);
					}
				}

			});

			this.find(".scroll-arrow a").each(function() {
				if ($($(this).attr("href")).length > 0) {
					$(this).scrollArrow();
				} else {
					$(this).closest(".scroll-arrow-cont").remove();
				}
			});


			return this;
		};

	}

)(jQuery);
